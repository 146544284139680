import React, { useEffect, useState } from "react";
// import { PricingPlans } from "../../Utils/dummydata";
import "../../Assets/Styles/Addons.css";
import { PurchaseAddon } from "../../Utils/apicalls";
import { toast } from "react-hot-toast";

import "animate.css";
import { useNavigate } from "react-router";

import Premium from "../../Assets/Images/premium.png";
import { AddonsMenu, AddonsYearly } from "../../Utils/dummyData.js";
import { getcurrdate, getExpDate } from "../../Utils/globalfunctions";
export const Addons = () => {
  const [UserAddons, setUserAddons] = useState([]);

  const [OrgID, setOrgID] = useState("");
  const [ActiveToggle, setActiveToggle] = useState(false);
  const SubscribePlan = (Item) => {
    var Domain = "";

    Domain = window.location.protocol + "//" + window.location.host + "/";

    var SubscriptionObj = {
      orgID: OrgID,
      AddOn_ID: Item.Addon_ID,
      AddOn_Name: Item.AddonTitle,
      AddOn_Price: Item.Price,
      AddOn_Type: "subscription",
      AddOn_Stripe_Identifier: Item.Stripe_Identifier,
      AddOn_CreatedDate: getcurrdate(),
      AddOn_Expiry: getExpDate(),
      MainDomain: Domain,
    };
    var resp = PurchaseAddon(SubscriptionObj);

    toast.promise(
      resp,

      {
        loading: "Please Wait",
        success: (data) => {
          if (data !== null) {
            if (data.Status == "Success") {
              window.open(data.RedirectUrl, "_blank");
              return "Redirecting To Payment Gateway";
            }
          } else {
            toast.error("Something went wront please try again");
          }
        },
        error: () => {
          toast.error("Something went wront please try again");
        },
      }
    );
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("UserDetails") == undefined)) {
    } else {
      var ORgID = JSON.parse(
        localStorage.getItem("UserDetails")
      ).organizationID;
      var USerAddons = JSON.parse(localStorage.getItem("UserDetails")).AddOns;
      setOrgID(ORgID);
      setUserAddons(USerAddons);
    }

    window.addEventListener("storage", (e) => {
      if (e.key == "Verify_PaymentStatus") {
        if (e.newValue == "Success") {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    });
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener("storage", () => {
        alert("removed");
      });
    },
    []
  );

  const CheckAddon = (AddonID) => {
    var USerAddons = JSON.parse(localStorage.getItem("UserDetails")).AddOns;

    var Mainres = false;
    var found = false;
    USerAddons.forEach((innnerItem) => {
      if (!found) {
        if (innnerItem.AddOn_Stripe_Identifier == AddonID) {
          Mainres = true;
          found = true;
        } else {
          Mainres = false;
        }
      }
    });

    return Mainres;
  };

  return (
    <>
      <div className="PurchaseModalBody mt-5 pt-3 container animate__animated  animate__bounceInUp  Modal_Container Settings_modal">
        {/* <div className="modal-header Settings_modal_Header m-0">
        <h5 className="modal-title" id="LeadsFilterModalLabel">Premium Addons</h5>
        <button type="button" className="close" id="ClosePurcase1" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> */}

        <div className="PurchaseModalInnerBody">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 InfoPanel">
              <h3>
                {" "}
                Premium Addons for <strong>MapView</strong>
              </h3>
              <p>
                {" "}
                With our Premium Addons, you can quickly and easily identify
                trends, optimize marketing strategies, and improve customer
                service, making it an essential tool for any business looking to
                improve customer retention and sales
              </p>
              <img src={Premium} />
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12 col-12 AddonsBody">
              <div className="AddonHeading">
                <h3>
                  {" "}
                  Expand your vision
                  <br />
                  <strong>All at an affordable price</strong>
                </h3>
                <p>
                  Are you corporate?
                  <br />
                  <strong>
                    <a href="mailto:info@zenithinnovations.net">Contact Us</a>
                  </strong>
                </p>
              </div>
              <div className="d-flex justify-content-center pb-3">
                <button
                  className={
                    !ActiveToggle ? "ToggleBtn ActiveToggle" : "ToggleBtn"
                  }
                  onClick={() => setActiveToggle(false)}
                >
                  Monthly
                </button>
                <button
                  className={
                    ActiveToggle ? "ToggleBtn ActiveToggle" : "ToggleBtn"
                  }
                  onClick={() => setActiveToggle(true)}
                >
                  Yearly
                </button>
              </div>
              <div className="row mainRow">
                {!ActiveToggle
                  ? AddonsMenu.map((Item, Key) => {
                      return (
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mainColumn" key={Key}>
                          <div
                            className={
                              Item.Status == "comingsoon"
                                ? "AddonItem Coming_Soon"
                                : "AddonItem"
                            }
                          >
                            <div className="AddonItemHeading text-left">
                              <h5>
                                <strong>{Item.AddonTitle}</strong>
                              </h5>
                              <h4>
                                {Item.Price}
                                <sub>/month</sub>
                              </h4>
                              {Item.Status == "comingsoon" ? <></> : <></>}
                            </div>
                            <p>{Item.Description}</p>
                            {Item.Status == "comingsoon" ? (
                              <button className="btn PurchaseBtn">
                                Coming Soon
                              </button>
                            ) : CheckAddon(Item.Stripe_Identifier) ? (
                              <button className="btn PurchaseBtn" disabled>
                                Active
                              </button>
                            ) : (
                              <button
                                className="btn PurchaseBtn"
                                onClick={() => SubscribePlan(Item)}
                              >
                                Purchase Now
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })
                  : AddonsYearly.map((Item, Key) => {
                      return (
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mainColumn" key={Key}>
                          <div
                            className={
                              Item.Status == "comingsoon"
                                ? "AddonItem Coming_Soon"
                                : "AddonItem"
                            }
                          >
                            <div className="AddonItemHeading text-left">
                              <h5>
                                <strong>{Item.AddonTitle}</strong>
                              </h5>
                              <h4>
                                {Item.Price}
                                <sub>/year</sub>
                              </h4>
                              {Item.Status == "comingsoon" ? <></> : <></>}
                            </div>
                            <p>{Item.Description}</p>
                            {Item.Status == "comingsoon" ? (
                              <button className="btn PurchaseBtn">
                                Coming Soon
                              </button>
                            ) : CheckAddon(Item.Stripe_Identifier) ? (
                              <button className="btn PurchaseBtn" disabled>
                                Active
                              </button>
                            ) : (
                              <button
                                className="btn PurchaseBtn"
                                onClick={() => SubscribePlan(Item)}
                              >
                                Purchase Now
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
