
import dateFormat from "dateformat";

export const getcurrdate=()=>{
    var date = new Date();
    date = dateFormat(date, "mm-dd-yyyy")   
    return date
}
export const getExpDate=()=>{
    var date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    date=dateFormat(date, "mm-dd-yyyy")   
    return date
}