import { GetOrgEndpoint, SubscribeEndpoint, } from "./endpoints";

export const PurchaseAddon=(AddonOBj)=>{

  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify(AddonOBj);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

return fetch(SubscribeEndpoint, requestOptions)
  .then(response => response.json())
  .then(result => {return result})
  .catch(error => console.log('error', error));
}



    export const GetOrgData=(zgid)=> {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
    
      var raw = JSON.stringify({
        orgID: zgid,
      });
    
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
    
      return fetch(
        GetOrgEndpoint,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          localStorage.setItem("UserDetails", JSON.stringify(result.FinalResp));
          return "Success";
        })
        .catch((error) => {
          return "Failure";
        });
    }
    

    export const VefiryPurchase=(Item)=>{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify(Item);
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
    return  fetch("https://mapview.api-apx.com/Map_api/v1/Verify_AddOn", requestOptions)
        .then(response => response.json())
        .then(result => {return result})
        .catch(error => console.log('error', error));
    }