export const AddonsMenu = [
  {
    AddonTitle: "Deals",
    Status: "Available",
    Description: "Monthly add-on for Mapview which allows users to edit and work with deals module on the Mapview.",
    Price: "$5",
    Addon_ID: "Deals_Addon",
    Stripe_Identifier: "price_1MdS0HC9lxNIJM1ZSqG4sG49",
  },

  {
    AddonTitle: "Custom Address Fields",
    Status: "available",
    Description: "Monthly add-on for Mapview which allows users to defined their own custom addresses for default and custom modules integrated in the Mapview.",
    Price: "$5",
    Addon_ID: "Address_Addon",
    Stripe_Identifier: "price_1MdS1hC9lxNIJM1ZcSke1QkW",
  },
  {
    AddonTitle: "Custom Module",
    Status: "available",
    Description: "Monthly add-on for Mapview which allows users to create and associate custom modules from your CRM into Mapview.",
    Price: "$8",
    Addon_ID: "Custom_Module_Addon",
    Stripe_Identifier: "price_1MdS37C9lxNIJM1Zw7lH9s9g",
  },
  {
    AddonTitle: "Route Planning & Sharing",
    Status: "available",
    Description: "Monthly add-on for Mapview which allows users to create and share routes with users.",
    Price: "$12",
    Addon_ID: "Route_Planning_Addon",
    Stripe_Identifier: "price_1OYiREC9lxNIJM1ZltJIEco7",
  },
  
];
export const AddonsYearly = [
  {
    AddonTitle: "Deals",
    Status: "Available",
    Description: "Yearly add-on for Mapview which allows users to edit and work with deals module on the Mapview.",

    Price: "$60",
    Addon_ID: "Deals_Addon",
    Stripe_Identifier: "price_1MsgW7C9lxNIJM1ZaTh7siUC",
  },

  {
    AddonTitle: "Custom Address Fields",
    Status: "available",
    Description: "Yearly add-on for Mapview which allows users to defined their own custom addresses for default and custom modules integrated in the Mapview.",
    Price: "$60",
    Addon_ID: "Address_Addon",
    Stripe_Identifier: "price_1MsgUmC9lxNIJM1ZrzsbtP7M",
  },
  {
    AddonTitle: "Custom Module",
    Status: "available",
    Description: "Yearly add-on for Mapview which allows users to create and associate custom modules from your CRM into Mapview.",
    Price: "$96",
    Addon_ID: "Custom_Module_Addon",
    Stripe_Identifier: "price_1MsgTgC9lxNIJM1ZtZRPuAMv",
  },
   {
    AddonTitle: "Route Planning & Sharing",
    Status: "available",
    Description: "Yearly add-on for Mapview which allows users to create and share routes with users.",
    Price: "$135",
    Addon_ID: "Route_Planning_Addon",
    Stripe_Identifier: "price_1OYiTOC9lxNIJM1ZebHUJXl7",
  },
];
