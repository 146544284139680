import React from 'react'

export const Home = () => {
  return (
  <>
  <div className="mapImageContainer">
   
    <section className="home-section" id="home">
        {/* <div className="home-section-overlay"></div> */}

     
        <div className="container Hometext">
           
            <div className="row">
              
                <div className="col-md-8 mx-auto text-center padding-top-120">

                    <img src="./assets/img/MapviewImages/logotop.png" width="350" alt="Banner Logo" className="mb-3" />

                    <h1>Bird Eye view of your data</h1>

                    <p className="hero-text">
                        MapView is an extension for Zoho CRM which helps the user to visualize their CRM data geographically on maps in a single frame by MapView. Mapview helps you reach your target audience to enhance your business and provides an aerial view of your heat points where your businesses can grow.
                    </p>

                  

                    <a className="btn btn-primary shadow p-3 mb-5 rounded text-capitalize Demo_Btn CTA_Btn" href="https://www.youtube.com/watch?v=dS2iWX5-5dM" target="_blank">
                        Watch Demo
                    </a>

                    <a href="https://marketplace.zoho.com/app/crm/map-view-for-zoho-crm" target="_blank" className="btn btn-primary shadow p-3 mb-5 rounded text-capitalize CTA_Btn">
                        Install MapView
                    </a>
                 
                    {/* <div className="popup-gallery-wrapper">
                     
                        <div className="popup-gallery hero-gallery">
                            <a className="popup4 video-icon"
                               href="https://www.youtube.com/watch?v=dS2iWX5-5dM">
                                <i className="fas fa-play"></i>
                            </a>
                        </div>
                       
                    </div> */}
                
                </div>
            
            </div>
     
        </div>
    
    </section>

    <section className="section-white" id='About'>
 
        <div className="container">
      
            <div className="row align-items-center">
       
                <div className="col-md-6 wow slideInLeft custStyle1"
                     data-wow-delay="0.25s">
                    <div className="margin-right-15">
                        <img src="/assets/img/MapviewImages/firstimage.jpg"
                             className="width-100 image-shadow bottom-margins-images"
                             alt="pic" />
                    </div>
                </div>
           
                <div className="col-md-6">
                    <h3>
                        Managing Leads, Contacts and Account Made Easier With MapView
                    </h3>

                    <p>
                        MapView seemlessly integrates with your Zoho CRM and maps all your
                        contacts, leads and accounts to Google Maps.
                    </p>

                    <ul className="benefits">
                        <li>
                            <i className="fas fa-check"></i>Bubble Map and Heat Map for data analysis.
                        </li>
                        <li>
                            <i className="fas fa-check"></i>Street view, Map view and Satellite view of the synced data.
                        </li>
                        <li>
                            <i className="fas fa-check"></i>View Leads accounts and contacts collectively on map.
                        </li>
                        <li>
                            <i className="fas fa-check"></i>Field Mapping to get all the details of a related record.
                        </li>
                    </ul>

                    <a href="#corefeatures"
                       className="btn btn-primary shadow p-3 mb-5 rounded text-capitalize CTA_Btn small scrool">Discover More</a>
                </div>
          
            </div>
        
        </div>

    </section>

    <section className="section-grey" id='features'>

        <div className="container" id="corefeatures">
            <h2 className="section-title text-center mb-5">Core Features</h2>
  
            <div className="row align-items-center">
     
                <div className="col-md-6">
                    <div className="nav flex-column nav-pills margin-right-25"
                         id="v-pills-tab"
                         role="tablist"
                         aria-orientation="vertical">
                        <a className="nav-link active"
                           id="v-pills-home-tab"
                           data-toggle="pill"
                           href="#v-pills-home"
                           role="tab"
                           aria-controls="v-pills-home"
                           aria-selected="true">
                        
                            <div className="features-second">
                                <div className="dropcaps-circle blue">
                                    <i className="far fa-address-book"></i>
                                </div>

                                <h4 className="margin-bottom-5">Manage Your Contacts</h4>

                                <p>
                                    Manage any process and be ready to address any challenge
                                    with total ease.
                                </p>
                            </div>
                    
                        </a>

                        <a className="nav-link"
                           id="v-pills-profile-tab"
                           data-toggle="pill"
                           href="#v-pills-profile"
                           role="tab"
                           aria-controls="v-pills-profile"
                           aria-selected="false">
                     
                            <div className="features-second">
                                <div className="dropcaps-circle red">
                                    <i className="fas fa-chart-bar"></i>
                                </div>

                                <h4 className="margin-bottom-5">Manage Your Leads</h4>

                                <p>
                                    With MapView getting your customers on board is as simple as
                                    sending an email.
                                </p>
                            </div>
                      
                        </a>

                        <a className="nav-link"
                           id="v-pills-messages-tab"
                           data-toggle="pill"
                           href="#v-pills-messages"
                           role="tab"
                           aria-controls="v-pills-messages"
                           aria-selected="false">
                    
                            <div className="features-second">
                                <div className="dropcaps-circle green">
                                    <i className="fas fa-users-cog"></i>
                                </div>

                                <h4 className="margin-bottom-5">Manage Your Accounts</h4>

                                <p>
                                    Analyze accounts by region and more and put a plan in place.
                                </p>
                            </div>
            
                        </a>
                        <a className="nav-link"
                           id="v-pills-vendors-tab"
                           data-toggle="pill"
                           href="#v-pills-vendors"
                           role="tab"
                           aria-controls="v-pills-vendors"
                           aria-selected="false">
                     
                            <div className="features-second">
                                <div className="dropcaps-circle green">
                                    <i className="fas fa-fax"></i>
                                </div>

                                <h4 className="margin-bottom-5">Manage Your Vendors</h4>

                                <p>
                                    All of your vendors located on the map for easier view.
                                </p>
                            </div>
              
                        </a>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="tab-content margin-left-20" id="v-pills-tabContent">
                        <div className="tab-pane fade show active"
                             id="v-pills-home"
                             role="tabpanel"
                             aria-labelledby="v-pills-home-tab">
                            <img src="./assets/img/MapviewImages/feature1.png"
                                 className="width-100 image-shadow top-margins-images"
                                 alt="pic" />
                        </div>

                        <div className="tab-pane fade"
                             id="v-pills-profile"
                             role="tabpanel"
                             aria-labelledby="v-pills-profile-tab">
                            <img src="./assets/img/MapviewImages/feature2.png"
                                 className="width-100 image-shadow top-margins-images"
                                 alt="pic" />
                        </div>

                        <div className="tab-pane fade"
                             id="v-pills-messages"
                             role="tabpanel"
                             aria-labelledby="v-pills-messages-tab">
                            <img src="./assets/img/MapviewImages/feature3.png"
                                 className="width-100 image-shadow top-margins-images"
                                 alt="pic" />
                        </div>
                        <div className="tab-pane fade"
                             id="v-pills-vendors"
                             role="tabpanel"
                             aria-labelledby="v-pills-vendors-tab">
                            <img src="./assets/img/MapviewImages/feature4.png"
                                 className="width-100 image-shadow top-margins-images"
                                 alt="pic" />
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </section>

    <section className="section-white" id="features">
      
        <div className="container">
        
            <div className="row">
             
                <div className="col-md-12 text-center padding-bottom-10">
                    <h2 className="section-title">Additional Features</h2>

                    <p className="section-subtitle">
                        MapView is packed with all the features you will ever need .
                    </p>
                </div>
              
            </div>
      
            <div className="row">
              
                <div className="col-md-4">
                    <div className="feature-box light-green wow fadeIn custStyle2"
                         data-wow-delay="0.25s"
             >
                        <i className="fas fa-layer-group"></i>

                        <div className="feature-box-text">
                            <h4>Bubble Map</h4>

                            <p>
                                Radius coverage map is a useful and powerful tool to see which
                                areas are under-served and which areas are over-served.
                            </p>
                        </div>
                    </div>
                </div>
    
                <div className="col-md-4">
                    <div className="feature-box light-blue wow fadeIn custStyle3"
                         data-wow-delay="0.5s"
              >
                        <i className="fas fa-map-marked-alt"></i>

                        <div className="feature-box-text">
                            <h4>Heat Map</h4>

                            <p>
                                A heat map is a data visualization technique that shows
                                magnitude of a phenomenon as color in two dimensions.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="feature-box orange wow fadeIn"
                         data-wow-delay="0.75s"
             >
                        <i className="fas fa-search-location"></i>

                        <div className="feature-box-text">
                            <h4>Search Bar</h4>

                            <p>
                                The search bar makes it easier for you to look up your desired
                                result. Simply enter a desired keyword which you want to search for.
                            </p>
                        </div>
                    </div>
                </div>
          
            </div>
 
            <div className="row">
         
                <div className="col-md-4">
                    <div className="feature-box dark-blue wow fadeIn custStyle5"
                         data-wow-delay="1s"
             >
                        <i className="fas fa-toggle-on"></i>

                        <div className="feature-box-text">
                            <h4>Complelely Customizable</h4>

                            <p>
                                You can now completely customize the visual theme based on your preference. Set the colors of the markers and a wide variety of pointers.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="feature-box light-red wow fadeIn custStyle5"
                         data-wow-delay="1.25s"
           
              >
                        <i className="fas fa-sort-amount-up"></i>

                        <div className="feature-box-text">
                            <h4>Fields Filter</h4>

                            <p>
                                The fields selection filter allows you to select and filter
                                out the specific fields from your data for your analytics. Mark the fields that you want to view.
                            </p>
                        </div>
                    </div>
                </div>
          
                <div className="col-md-4">
                    <div className="feature-box dark-green wow fadeIn custStyle5 "
                         data-wow-delay="1.5s"
           >
                        <i className="fa-solid fa-filter-circle-xmark"></i>

                        <div className="feature-box-text">
                            <h4>Custom Filters</h4>

                            <p>
                               Now you can create custom filter for your data based on fields having specific values.
                            </p>
                        </div>
                    </div>
                </div>
         
            </div>
     
        </div>

    </section>

    <section className="section-grey" id="testimonials">
     
        <div className="container">
        
            <div className="row align-items-center">
        
                <div className="col-md-5 col-sm-12">
                    <h2 className="section-title">What Users Are Saying</h2>

                    <p className="text-center">
                        Have a look at what our customer’s have to say about our MapView extension
                    </p>

           
                    <div className="row d-flex justify-content-center">
                  
                        <div className="col-md-4 col-sm-6 d">
                 
                            <div className="testim-platform first">
                                <p className="text-center">Google</p>

                                <div className="testim-rating text-center">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fas fa-star-half-alt" aria-hidden="true"></i>
                                </div>
                            </div>
                  
                        </div>
               
                        <div className="col-md-4 col-sm-6">
                      
                            <div className="testim-platform">
                                <p className="text-center">TrustPilot</p>

                                <div className="testim-rating text-center">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="far fa-star" aria-hidden="true"></i>
                                </div>
                            </div>
             
                        </div>
        
                    </div>

                </div>
  
                <div className="col-md-1"></div>
 
                <div className="col-md-6">
        
                    <div className="accordion" id="accordionFAQ">
              
                        <div className="card">
                            <div id="collapseOne"
                                 className="collapse show"
                                 aria-labelledby="headingOne"
                                 data-parent="#accordionFAQ">
                                <div className="card-body">
                                    It is a great extension which helped me a lot in managing CRM data. Managing leads, Accounts and Contacts was never so easy.
                                </div>
                            </div>

                            <div className="card-header" id="headingOne">
                                <h5 className="mb-0">
                                    <button className="btn btn-link"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne">
                                        <img src="./assets/img/MapviewImages/testimonials1.jpg"
                                             alt="testimonials"
                                             className="testim-img" />
                                        <p className="testim-name">
                                            Emma Lenneti
                                        </p>
                                    </button>
                                </h5>
                            </div>
                        </div>
           
                        <div className="card">
                            <div id="collapseTwo"
                                 className="collapse"
                                 aria-labelledby="headingTwo"
                                 data-parent="#accordionFAQ">
                                <div className="card-body">
                                    Highly recommended for data visualization. I can easily locate my leads and contacts on map through this extension.
                                </div>
                            </div>

                            <div className="card-header" id="headingTwo">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo">
                                        <img src="./assets/img/MapviewImages/testimonials2.jpg"
                                             alt="testimonials"
                                             className="testim-img" />
                                        <p className="testim-name">
                                            Charles Peterson
                                        </p>
                                    </button>
                                </h5>
                            </div>
                        </div>
             
                        <div className="card">
                            <div id="collapseThree"
                                 className="collapse"
                                 aria-labelledby="headingThree"
                                 data-parent="#accordionFAQ">
                                <div className="card-body">
                                    This is one of the best extension which helps me sync data to get a clear picture of the target audience on Map.
                                </div>
                            </div>

                            <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <img src="./assets/img/MapviewImages/testimonials3.jpg"
                                             alt="testimonials"
                                             className="testim-img" />
                                        <p className="testim-name">
                                            Rachel Dsouza
                                   
                                        </p>
                                    </button>
                                </h5>
                            </div>
                        </div>
           
                    </div>
         
                </div>
        
            </div>
  
        </div>
   
    </section>


    <section className="section-white" id="faq">

        <div className="container">

            <div className="row">
            
                <div className="col-md-12 text-center padding-bottom-10">
                    <h2 className="section-title">Frequently Asked Questions</h2>

                    <p className="section-subtitle"></p>
                </div>
       
            </div>
     
            <div className="row">
          
                <div className="col-md-6">
                    <div className="faq-box">
                        <h5>How do I cancel my subscription?</h5>

                        <p>
                            In order to cancel your subcription simply go to settings tab
                            and click cancel subscription.
                        </p>
                    </div>

                    <div className="faq-box">
                        <h5>How do I contact the support team?</h5>

                        <p>
                            You can contact the support team via live chat as well as send
                            us an email. A support agent will get back to you shortly.
                        </p>
                    </div>
                </div>
         
                <div className="col-md-6">
                    <div className="faq-box">
                        <h5>Are there any in-app purchases?</h5>

                        <p>
                            No, currently all of the features are included in one the time
                            subscription.
                        </p>
                    </div>

                    <div className="faq-box">
                        <h5>Can I upgrade my plan?</h5>

                        <p>
                            Yes you can upgrade the plan anytime by simply paying the
                            difference amount.
                        </p>
                    </div>
                </div>

           
            </div>
       
        </div>
     
    </section>
  
</div>
  </>
  )
}
